<script>
	import { page } from '$app/stores';
</script>

<nav class="flex w-full justify-around items-center h-20 mb-4">
	<div class="">
		<a href="/">
			<button
				class="mx-4 text-lg p-2 hover:font-light text-neutral-300 duration-200   {$page.routeId ===
					'' && 'font-bold text-think-500 border-b-think-500 border-solid border-b-2'} "
				>Perfil</button
			>
		</a>

		<a href="/project">
			<button
				class="mx-4 text-lg hover:font-bold text-neutral-300 duration-200 {$page.routeId ===
					'project' && 'font-bold text-think-500 border-b-think-500 border-solid border-b-2'} "
				>Projeto</button
			>
		</a>

		<a href="/videos">
			<button
				class="mx-4 text-lg hover:font-bold text-neutral-300 duration-200 {$page.routeId ===
					'videos' && 'font-bold text-think-500 border-b-think-500 border-solid border-b-2'}"
				>Vídeos</button
			>
		</a>

		<a href="/methodology">
			<button
				class="mx-4 text-lg hover:font-bold text-neutral-300 duration-200 {$page.routeId ===
					'methodology' && 'font-bold text-think-500 border-b-think-500 border-solid border-b-2'}"
				>Metodologia</button
			>
		</a>
	</div>
</nav>
